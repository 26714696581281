<template>
  <div>
    <SentenceForm
      :first_content="ra_base.action"
      :second_content="ra_base.content"
      :third_content="ra_base.condition"
      :full_sentence="ra_base.full_sentence"
      :verb="ra_base.verb"
      third_content_comment
      @update="slotUpdateSentence"
    ></SentenceForm>
    <div class="row mt-2">
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <b-button class="mr-1" size="sm" variant="danger" @click="remove"
          >Eliminar
          {{ $getVisibleNames("teaching.basera", false, "RA Base") }}</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save"
          >Guardar
          {{ $getVisibleNames("teaching.basera", false, "RA Base") }}</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
// import { validationMixin } from "vuelidate";
// import { toast } from "@/utils/utils";

export default {
  name: "RaBaseForm",
  components: {
    SentenceForm: () => import("./SentenceForm"),
  },
  props: {
    RABase: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          verb: null,
          action: "",
          content: "",
          condition: "",
          full_sentence: "",
          competences: [],
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    competences_ids: {
      type: Array,
      // required: true,
    },
  },
  data() {
    return {
      ra_base: { ...this.RABase },
      base_sentence_toggle: this.RABase
        ? this.RABase.content
          ? true
          : false
        : true,
    };
  },
  computed: {
    ...mapGetters({
      ra_bases: names.RA_BASES,
    }),
    raBasesLength() {
      if (!this.competences_ids) return 1;
      return this.ra_bases.filter(
        (x) =>
          x.competences.filter((c) => this.competences_ids.includes(c)).length >
          0
      ).length;
    },
  },
  methods: {
    slotUpdateSentence(sentence) {
      this.ra_base.action = sentence.first_content;
      this.ra_base.verb = sentence.verb;
      this.ra_base.content = sentence.second_content;
      this.ra_base.condition = sentence.third_content;
      this.ra_base.full_sentence = sentence.full_sentence;
      this.base_sentence_toggle = sentence.base_sentence_toggle;
    },
    remove() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.basera",
          false,
          "RA Base"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_RA_BASE, this.ra_base.id)
            .then(() => {
              this.$emit("deleted", this.ra_base);
              toast(
                "Eliminado el " +
                  this.$getVisibleNames("teaching.basera", false, "RA Base")
              );
            });
        }
      });
    },
    save() {
      this.ra_base.competences = this.competences_ids;
      if (this.base_sentence_toggle) {
        if (
          this.ra_base.action.trim() == "" ||
          this.ra_base.content.trim() == "" ||
          this.ra_base.condition.trim() == ""
        ) {
          return;
        }
        this.ra_base.full_sentence = String(
          this.ra_base.action +
            " " +
            this.ra_base.content +
            " " +
            this.ra_base.condition
        );
        if (isNaN(this.ra_base.id)) this.createRaBase();
        else this.updateRaBase();
      } else {
        if (
          this.ra_base.full_sentence ||
          !this.ra_base.full_sentence.trim() == ""
        ) {
          if (isNaN(this.ra_base.id)) this.createRaBase();
          else this.updateRaBase();
        }
      }
    },
    createRaBase() {
      this.ra_base.order = this.raBasesLength + 1;
      this.$store
        .dispatch(names.POST_RA_BASE, this.ra_base)
        .then((response) => {
          toast(
            this.$getVisibleNames("teaching.basera", false, "RA Base") +
              " creado."
          );
          this.$emit("created", response);
        });
    },
    updateRaBase() {
      this.$store
        .dispatch(names.UPDATE_RA_BASE, this.ra_base)
        .then((response) => {
          toast(
            this.$getVisibleNames("teaching.basera", false, "RA Base") +
              " actualizado."
          );
          this.$emit("updated", response);
        });
    },
  },
  created() {},
};
</script>
<style  scoped>
</style>